<template>
  <div>
      <el-card>
      <div class="tablemap">
           <el-table
      :data="tableData"
      style="width: 100%">
      <el-table-column
        type="index"
        label="编号"
      >
      </el-table-column>
      <el-table-column
        
        label="产品名称"
        width="180">
         <template slot-scope="scope">
           {{scope.row.cpmc.cpmc}}
            
          </template>
      </el-table-column>
      <el-table-column
        prop="sym"
        label="追溯码">
      </el-table-column>
      <el-table-column
        prop="pch"
        label="批次号">
      </el-table-column>
     
     
       
    </el-table>
       <!-- 分页区域 -->
      <el-pagination
        background
        layout="prev, pager, next,total"
        :total="total"
        :page-size="queryInfo.pageSize"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
      </div>
       <div id="mapDiv" class="mapDiv" ref="mapDiv"></div>
       </el-card>
  </div>
</template>

<script>
import tiandi from "../../../assets/js/maptiandi"
export default {
   data(){
       return{
        tableData:[],
        total:0,
        queryInfo:{
            page:1,
            pageSize:5
        }
       }
   },
   created(){
       this.getTable()
   },
   methods:{
       getTable(){
           this.$http.post('SaomaList',this.queryInfo).then(res=>{
               console.log(res)
               this.tableData = res.data.data.result
               this.total=res.data.data.total
               this.roadMap(this.tableData[0].jd,this.tableData[0].wd)
           })

       },
        roadMap(lng,lat ) {
    console.log(this.tableData[0])
      tiandi.init().then(
          
        T => {
          this.T = T;
          const imageURL = "http://t0.tianditu.gov.cn/img_c/wmts?tk=b02dec10c18d1827f87ef79acf6cd3c0";
          const lay = new T.TileLayer(imageURL, { minZoom: 1, maxZoom: 18 });
          const config = { layers: [lay], name: 'TMAP_SATELLITE_MAP' };
          this.map = new T.Map('mapDiv');
          
          
          const ctrl = new T.Control.MapType();
          this.map.addControl(ctrl);
          this.map.centerAndZoom(new T.LngLat(lng, lat),8);
          // this.map.setStyle('indigo');
          var arrayObj = new Array();
          var icon = new T.Icon({
                iconUrl: "http://api.tianditu.gov.cn/img/map/markerA.png",
                iconSize: new T.Point(25, 35),
                iconAnchor: new T.Point(10, 25)
            });
          for(var i=0;i<this.tableData.length;i++){
            var marker = new T.Marker(new T.LngLat(this.tableData[i].jd,this.tableData[i].wd), {icon: icon});
            this.map.addOverLay(marker);
            arrayObj.push(marker)
          }
          console.log(marker)
          
        //   var markers = new T.MarkerClusterer(this.map, {markers: arrayObj});
        }).catch()
        
    },
   }
}
</script>

<style>
.tablemap{
    display: inline-block;
    vertical-align: top;
    width: 30%;
}
.mapDiv{
    margin-left: 1vw;
    display: inline-block;
    width: 68%;
    height: 600px;
}
</style>